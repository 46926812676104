<template>
  <div>
    <v-card>
      <v-card-title class="d-flex bd-highlight align-center flex-wrap">
        Retenção da carteira - Regional Mensal
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap mt-5 pb-0">
        <v-row class="mb-3">
          <v-col
            cols="12"
            md="4"
            class="pb-1"
          >
            <v-autocomplete
              v-model="reference"
              :items="referenceList"
              item-text="name"
              item-value="name"
              label="Periodo"
              outlined
              dense
              hide-details
              cols="12"
              md="3"
              sm="4"
              lg="3"
              class="pb-1"
              @input="onInput"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="dataTable"
        group-by="reference"
        loading-text="Carregando dados..."
        :loading="loading"
        disable-sort
        hide-default-footer
      >
        <template
          v-slot:no-data
        >
          <span :class="classNoDataText">{{ noDataText }}</span>
        </template>
        <template
          v-slot:group.header="{items}"
        >
          <td
            colspan="10"
          >
            {{ items[0].reference }}
          </td>
        </template>
      </v-data-table>

      <!-- <v-card-text>
        <v-row>
          <v-col>
            <v-pagination
              v-model="pageOptions.page"
              total-visible="5"
              :length="pageOptions.pageCount"
              @input="nextPage"
            />
          </v-col>
        </v-row>
      </v-card-text> -->
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiCalculatorVariantOutline, mdiFilterOutline, mdiMinusCircleOutline } from '@mdi/js'

export default {
  data() {
    return {
      itemsRegion: [],
      classNoDataText: '',
      noDataText: '',
      region: '',
      reference: '',
      referenceList: [],
      icons: {
        mdiCalculatorVariantOutline,
        mdiFilterOutline,
        mdiMinusCircleOutline,
      },
      page: 1,
      headers: [
        { text: 'REGIÃO', value: 'region' },
        { text: 'VENDA', value: 'venda' },
        { text: 'PARC 01', value: 'parcela01' },
        { text: 'PARC 02', value: 'parcela02' },
        { text: 'PARC 03', value: 'parcela03' },
        { text: 'PARC 04', value: 'parcela04' },
        { text: 'PARC 05', value: 'parcela05' },
        { text: '% PARC 4', value: 'retencao04' },
        { text: '% PARC 5', value: 'retencao05' },
        { text: 'RETENÇÃO', value: 'desempenho' },
      ],
      dataTable: [],
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      loading: false,
    }
  },

  async created() {
    this.noDataText = 'SELECIONE UM FILTRO'
    this.classNoDataText = 'colorWarning'
    await this.getReferenceList()
    await this.getFilterItems()
  },

  methods: {
    async onInput() {
      await this.getFilterItems()
    },

    async getReferenceList() {
      await axiosIns.get('/api/v1/integration/contract/list_references').then(res => {
        this.referenceList = res.data.data.map(item => item.reference)
        this.loadingReferenceList = false
      })
    },

    // async nextPage() {
    //   this.loading = true
    //   this.dataTable = []
    //   const filter = {
    //     vendor_name: this.region,
    //   }
    //   await axiosIns.post(`api/v1/integration/retention/portfolio_retention?page=${this.pageOptions.page}`, filter)
    //     .then(res => {
    //       this.dataTable = res.data.data
    //       this.pageOptions.itemsPerPage = res.data.per_page
    //       this.pageOptions.pageCount = res.data.last_page
    //       this.loading = false
    //     })
    // },

    async getFilterItems() {
      this.loading = true
      this.noDataText = 'NENHUM REGISTRO ENCONTRADO'
      this.classNoDataText = ''

      if (!this.reference) {
        // eslint-disable-next-line prefer-destructuring
        this.reference = this.referenceList[0]
      }

      const filter = {
        reference: this.reference,
      }

      await axiosIns.post('api/v1/integration/contract/quality_sale_regions_retention', filter).then(res => {
        this.dataTable = res.data.data
        this.loading = false

        // this.pageOptions.itemsPerPage = res.data.per_page
        // this.pageOptions.pageCount = res.data.last_page
      })
      this.loading = false
    },
    clearFilter() {
      this.dataTable = []
      this.noDataText = 'SELECIONE UM FILTRO'
      this.classNoDataText = 'colorWarning'
      this.reference = ''
    },
  },
}
</script>
<style>
.colorWarning {
  color: #ff9800 !important;
}
</style>
